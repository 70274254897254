import {
    ConfigQuery as SourceConfigQuery,
} from 'SourceQuery/Config.query';

/** @namespace Scandipwa/Query/Config/Query */
export class ConfigQuery extends SourceConfigQuery {
    /**
     * Overridden to fix core typo
     */
    _getStoreConfigFields() {
        const withoutTypo = super._getStoreConfigFields()
            .filter((field) => field !== 'minimun_password_length');

        return [
            ...withoutTypo,
            'minimum_password_length',
        ];
    }
}

export default new ConfigQuery();
